import React from "react";
// import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LABELS as _LABELS } from "../../language";

const LABELS = _LABELS.dutyPage;

export default function StatusButtons(props) {
  const [allDetail, setAllDetail] = React.useState(false);

  return (
    <FormControlLabel
      style={{ padding: 0, float: "right" }}
      control={
        <Checkbox
          checked={allDetail}
          onChange={(e) => {
            const isChecked = e.target.checked;
            setAllDetail(isChecked);
            props.onChange(isChecked);
          }}
          style={{ padding: 0 }}
          name="detailLogs"
          color="primary"
        />
      }
      label={`Details`}
    />
  );
}
