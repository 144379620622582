import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { LABELS as _LABELS } from "../../language";
import MenuIcon from '@mui/icons-material/Menu';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import Button from "@mui/material/Button";
// import IconButton from "@mui/material/IconButton";

const LABELS = _LABELS.dutyPage;

export default function SimpleMenu(props) {
  const isFmcsaSend = props.isFmcsaSend || false;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function onMenuSelect(event, item) {
    handleClose(event);
    props.onMenuSelect(item);
  }

  // const menuItems = {
  //   REASS_MULTI: "Reassign Multiple Status",
  //   ADD_MULT: "Add Multiple Status",
  //   EDIT_MULT: "Edit Multiple Status",
  //   ADD_STATUS: "Add Status",
  //   SEND_MSG: "Send Message",
  //   SEND_NTG: "Send Notification",
  //   ADD_STATUS_ADMIN: "Add Status(Admin)",
  //   ADD_SHIP_ADMIN: "Add Shipping Or Trailer",
  //   REASS_MULTI_ADMIN: "Reassign Multiple Status(Admin)",
  //   REFRESH: "Refresh",
  //   DOWNLOAD: "Download",
  //   RECAP: "Recap",
  //   AUDIT: "Audit Events",
  //   MANAGE_DRIVER: "Manage Drivers",
  //   NEW_TAB: "Open In New Tab",
  // };

  const menuItems = {
    REASS_MULTI: LABELS.ReassignMultipleStatus,
    ADD_MULT: LABELS.AddMultipleStatus,
    EDIT_MULT: LABELS.EditMultipleStatus,
    ADD_STATUS: LABELS.AddStatus,
    SEND_MSG: LABELS.SendMessage,
    SEND_NTG: LABELS.SendNotification,
    ADD_STATUS_ADMIN: LABELS.AddStatusAdmin,
    ADD_SHIP_ADMIN: LABELS.AddShippingOrTrailer,
    REASS_MULTI_ADMIN: LABELS.ReassignMultipleStatusAdmin,
    REFRESH: LABELS.refresh,
    DOWNLOAD: LABELS.download,
    RECAP: LABELS.recap,
    AUDIT:LABELS.AuditEvent,
    MANAGE_DRIVER: LABELS.manageDrivers,
    NEW_TAB: LABELS.openInNewTab,
  };
  return (
    <>
      <Button
        aria-label="Menu"
        color="primary"
        onClick={handleClick}
        aria-haspopup="true"
      >
        <MenuIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={(e) => onMenuSelect(e, menuItems.RECAP)}>
          {`${menuItems.RECAP}`}
        </MenuItem>
        <MenuItem onClick={(e) => onMenuSelect(e, menuItems.REFRESH)}>
          {`${menuItems.REFRESH}`}
        </MenuItem>
        <MenuItem
          disabled={props.downloadDisabled}
          onClick={(e) => onMenuSelect(e, menuItems.DOWNLOAD)}
        >
          {`${menuItems.DOWNLOAD}`}
        </MenuItem>

        {props.adminKey && (
          <MenuItem onClick={(e) => onMenuSelect(e, menuItems.AUDIT)}>
            {`${menuItems.AUDIT}`}
          </MenuItem>
        )}
        {/*<MenuItem onClick={(e) => onMenuSelect(e, menuItems.NEW_TAB)}>
          {`${menuItems.NEW_TAB}`}
        </MenuItem>*/}

        {(props.adminKey || props.profile === "MANAGER") && (
          <MenuItem
            disabled={isFmcsaSend}
            onClick={(e) => onMenuSelect(e, menuItems.MANAGE_DRIVER)}
          >
            {`${menuItems.MANAGE_DRIVER}`}
          </MenuItem>
        )}

        {props.adminKey && props.coDriver && (
          <MenuItem
            disabled={isFmcsaSend}
            onClick={(e) => onMenuSelect(e, menuItems.REASS_MULTI)}
          >{`${menuItems.REASS_MULTI}`}</MenuItem>
        )}
        {props.adminKey && props.coDriver && (
          <MenuItem
            onClick={(e) => onMenuSelect(e, menuItems.ADD_MULT)}
            disabled={isFmcsaSend}
          >{`${menuItems.ADD_MULT}`}</MenuItem>
        )}
        {(props.adminKey || props.profile === "MANAGER") &&
          !props.isMultiDay && (
            <MenuItem
              disabled={isFmcsaSend}
              onClick={(e) => onMenuSelect(e, menuItems.EDIT_MULT)}
            >{`${menuItems.EDIT_MULT}`}</MenuItem>
          )}
        <MenuItem
          disabled={isFmcsaSend}
          onClick={(e) => onMenuSelect(e, menuItems.ADD_STATUS)}
        >
          {`${menuItems.ADD_STATUS}`}
        </MenuItem>
        {props.adminKey && (
          <MenuItem
            onClick={(e) => onMenuSelect(e, menuItems.SEND_MSG)}
          >{`${menuItems.SEND_MSG}`}</MenuItem>
        )}
        {props.adminKey && (
          <MenuItem
            onClick={(e) => onMenuSelect(e, menuItems.SEND_NTG)}
          >{`${menuItems.SEND_NTG}`}</MenuItem>
        )}
        {props.adminKey && (
          <MenuItem
            onClick={(e) => onMenuSelect(e, menuItems.ADD_SHIP_ADMIN)}
          >{`${menuItems.ADD_SHIP_ADMIN}`}</MenuItem>
        )}
        {props.isAdminDs && (
          <MenuItem
            disabled={isFmcsaSend}
            onClick={(e) => onMenuSelect(e, menuItems.ADD_STATUS_ADMIN)}
          >{`${menuItems.ADD_STATUS_ADMIN}`}</MenuItem>
        )}
        {props.isAdminDre && (
          <MenuItem
            disabled={isFmcsaSend}
            onClick={(e) => onMenuSelect(e, menuItems.REASS_MULTI_ADMIN)}
          >{`${menuItems.REASS_MULTI_ADMIN}`}</MenuItem>
        )}
      </Menu>
    </>
  );
}
