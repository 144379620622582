import React from "react";
import _get from "lodash/get";
import moment from "moment";
import queryString from "query-string";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@mui/styles";
// import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import LogGrid from "./LogGrid";
import EmptyLogGrid from "./EmptyLogGrid";
import StatusButtons from "./StatusButtons";
import DetailButtons from "./DetailButtons";
import UpdateStatusModal from "./UpdateStatusModal";
import DateWithButton from "../../components/DateWithButton";
import IconButton from "@mui/material/IconButton";
import SyncIcon from "@mui/icons-material/Sync";
import { CircularProgress } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { getDriverById } from "../../actions/driverActions";
import { setTimeZone } from "../../actions/app/appActions";
import {
  getDriverLogs,
  getDriverDaily,
  setSelectedLog,
  deleteDriverLog,
  getEditRequest,
  getDriverLogById,
  deleteRequest,
  getAllCalcus,
  updateDriverDaily,
} from "../../actions/logActions";
import {
  deleteAdminLogs,
  updateAdminLogs,
  deleteAdminCalcus,
} from "../../actions/account/accountAdminActions";
import {
  getDailyLogsDays,
  formatTime,
  formatRawDate,
  formatDateTime,
  getEndOfDay,
  getStartOfDay,
  formatDateTimeUtc,
  formatTimeSec,
  formatDateTimeToUtc,
} from "../../actions/momentActions";
import { getAllDvir, getAllDvirCount } from "../../actions/dvirActions";
import CurrentStatusDetailTable from "./CurrentStatusDetailTable";
import CurrentStatusDetailTableNew from "./CurrentStatusDetailTableNew";
import { getAllDayEvents, filterDriverLogs, getAutoDrLimits } from "./helpers";
import DailyStatusInfoTable from "./DailyStatusInfoTable";
import DvirTable from "../Dvir/DvirTable";
import RecapDialog from "./RecapDialog";
import AddDailyStatusModal from "./AddDailyStatusModal";
import ManageDriverModal from "./ManageDriverModal";
import StatusAuditModal from "./StatusAuditModal";
import PendingRequest from "./PendingRequest";
import Violations from "./Violations";
import Exceptions from "./Exceptions";
import AllLogEdit from "./AllLogEdit";
import {
  getLatestEvent,
  // isPastDate,
  dateToday,
} from "../../utils/momentHelpers";
import { getLogPostUrl, getLogGetUrl } from "../../actions/documentUpload";
import LogCertifyButton from "./LogCertifyButton";
import AddShippingDialog from "./AddShippingDialog";
import MessageDialog from "../Notifications/MessageDialog";
import { getFireBaseErrors } from "../../actions/adminDashActions";
import { getFmcsaHistory } from "../../actions/fmcsaActions";
import DriverErrors from "./DriverErrors";
import SmsForm from ".././Setting/User/Forms/SmsForm";
import EditMultipleStatusModal from "./EditMultipleStatus/MultipleStatusModal";
import ReMultipleStatusModal from "./ReassignMultipleStatus/ReMultiStatusModal";
import ReMultiStatusAdminModal from "./ReassignMultipleStatusAdmin/ReMultiStatusAdminModal";
import AddMultipleStatusModal from "./AddMultipleStatus/AddMultiStatusModal";
import LogCertifyDownload from "./LogCertifyDownload";
import StatusViewMenu from "./StatusViewMenu";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import RestoreIcon from "@mui/icons-material/Restore";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import EditIcon from "@mui/icons-material/Edit";
// import ReplyIcon from "@mui/icons-material/Reply";
// import ListIcon from "@mui/icons-material/List";
// import SmsIcon from "@mui/icons-material/Sms";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import NotificationsIcon from "@mui/icons-material/Notifications";
// import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import LaunchIcon from "@mui/icons-material/Launch";
// import ContactSupportIcon from "@mui/icons-material/ContactSupport";
// import AppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";

import { LABELS as _LABELS } from "../../language";

const LABELS = _LABELS.dutyPage;

const styles = (theme) => ({
  root: {
    padding: 5,
    flexGrow: 1,
  },
  AppBar: {
    padding: 5,
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: 10,
  },
  driverHeader: {
    textAlign: "center",
    fontWeight: 500,
  },
  driverTitle: {
    fontWeight: 400,
    fontSize: 12,
    marginTop: 10,
  },
  driverContent: {
    fontSize: 12,
    marginTop: 5,
    paddingLeft: 10,
  },
  carrierHeader: {
    textAlign: "center",
    fontWeight: 500,
    marginTop: 20,
  },
  textRight: {
    textAlign: "right",
  },
  textCenter: {
    textAlign: "center",
  },
  menuWrapper: {},
  buttonContainer: {
    padding: 8,
  },
  statusCheckBoxWrapper: {
    paddingLeft: 25,
  },
  sepLine: {
    display: "inline-block",
    borderLeft: "1px solid lightgrey",
    height: "33px",
    position: "absolute",
  },
});

export class DailyStatusDetail extends React.Component {
  constructor(props) {
    super(props);
    const values = queryString.parse(window.location.search);
    this.state = {
      open: false,
      loading: false,
      showAll: false,
      openRecap: false,
      isAllLog: props.isUnity || !!props.adminKey ? true : values.isAllLog,
      selectedDate: values.selectedDate || new Date(),
      openCertify: false,
      isFmcsaSend: false,
      errors: [],
      isNotificationDialog: false,
      openSms: false,
      autoDrLimits: [],
      selectedLogs: [],
      fmcsa: [],
      isAuditModal: false,
      dvirs: [],
      isDvir: false,
      isNoDvir: false,
      limit: 10,
      page: 0,
    };
  }

  componentDidMount() {
    this.searchLogs(this.state.selectedDate);
    // await this.props.getAllDvirCount({});
    // const driverId = _get(this.props, "selectedDriver.driver.id", "");
    // if (driverId) {
    //     getErrors(driverId, (errors) => {
    //         this.setState({ errors })
    //     });
    // }
  }

  componentWillUnmount() {
    const timezone = _get(this, "props.defaultTimezone");
    this.props.setTimeZone(timezone);
  }

  reload = () => {
    this.setState({ selectedLogs: [] });
    this.searchLogs(this.state.selectedDate);
  };

  getDriverDaily = async () => {
    const selectedDate = this.state.selectedDate;
    const driverId = _get(this, "props.match.params.driverId");
    const dailyDiary = await this.props.getDriverDaily({
      driverId,
      date: this.props.formatRawDate(selectedDate),
    });

    this.setState({ dailyDiary });
  };

  getEditRequest = async () => {
    const selectedDate = this.state.selectedDate;
    const days = this.props.getDailyLogsDays(selectedDate, 7);
    const driverId = _get(this, "props.match.params.driverId");
    const editRequests = await this.props.getEditRequest({
      driverId,
      endOfDay: days.endOfDay,
      startOfDay: days.startOfDay,
    });
    this.setState({ editRequests });
  };

  getAllCalcus = async () => {
    const driverId = _get(this, "props.match.params.driverId");
    const driverCalcus = await this.props.getAllCalcus({ driverId });
    this.setState({ driverCalcus });
    return driverCalcus;
  };

  getAllFmcsa = async () => {
    if (this.props.profile && this.props.profile.includes("DRIVER")) {
      return;
    }

    try {
      this.setState({ isFmcsaSend: false });
      const accountId = this.props.accountId;
      const driverId = _get(this, "props.match.params.driverId");

      const payload = {
        accountId,
        driverId,
        limit: 100,
        skip: 0,
      };

      const fmcsa = await this.props.getFmcsaHistory(payload);

      if (fmcsa && Array.isArray(fmcsa)) {
        this.setState({ fmcsa });
      }
    } catch (e) {
      console.log("e :::: ", e);
    }
  };

  validateFmcsa = () => {
    const fmcsa = this.state.fmcsa;
    const selectedDate = this.state.selectedDate;

    for (let i = 0; i < fmcsa.length; i++) {
      const ss = fmcsa[i] || {};
      // const fromDate = ss.fromDate;
      // const toDate = ss.toDate;
      const isFromSame = moment(selectedDate).isSame(ss.fromDate, "day");
      const isToSame = moment(selectedDate).isSame(ss.toDate, "day");
      const isSame = moment(selectedDate).isBetween(ss.fromDate, ss.toDate);

      if (isFromSame || isToSame || isSame) {
        this.setState({ isFmcsaSend: true });
      }
    }
  };

  searchLogs = async (selectedDate, showAll) => {
    this.setDateinQuery(selectedDate);
    this.getAllFmcsa();
    const driverId = _get(this, "props.match.params.driverId");
    const selDriver = await this.props.getDriverById({ driverId });

    this.setState({ selectedDate, loading: true }, () => {
      this.getDriverDaily();
      this.getAllDvir();
    });

    const timezone = _get(selDriver, "terminal.timezone");
    this.props.setTimeZone(timezone);

    const days = this.props.getDailyLogsDays(selectedDate, 1);

    const allLogs =
      (await this.props.getDriverLogs({
        showAll,
        driverId,
        ...days,
      })) || {};

    const allCalcus = await this.getAllCalcus();

    const driverLogs = allLogs.driverLogs || [];
    const driverLastLogs = allLogs.driverLastLogs || {};
    const driverNextLogs = allLogs.driverNextLogs || {};

    await this.getEditRequest();
    this.validateFmcsa(selectedDate);

    const allEvents = getAllDayEvents(
      timezone,
      selectedDate,
      driverLogs,
      driverLastLogs,
      driverNextLogs
    );
    const autoDrLimits = getAutoDrLimits(allEvents);
    const allDayEvents = filterDriverLogs(allEvents, allCalcus);

    this.setState({
      loading: false,
      driverLogs,
      allDayEvents,
      allEvents,
      autoDrLimits,
    });
  };

  getAllDvir = async () => {
    if (this.props.profile && this.props.profile.includes("DRIVER")) {
      return;
    }
    this.setState({ loading: true });
    const selectedDate = this.state.selectedDate;
    const driverId = _get(this, "props.match.params.driverId");

    const responseDvir = await this.props.getAllDvir({
      limit: 10,
      skip: 0,
      driverId,
      start: new Date(this.props.getStartOfDay(selectedDate)),
      end: new Date(this.props.getEndOfDay(selectedDate)),
    });
    if (
      responseDvir &&
      Array.isArray(responseDvir) &&
      responseDvir.length !== 0
    ) {
      this.setState({ dvirs: responseDvir, isNoDvir: false });
    } else {
      this.setState({ dvirs: [], isNoDvir: true });
    }
    // this.setState({ loading: false });
  };

  // handleChange = ({ limit, page }) => {
  //   this.setState({ limit, page }, this.getAllDvir);
  // };
  //
  // handleSearchChange = ({ driverId, vehicleId, start, end }) => {
  //   this.setState({ driverId, vehicleId, start, end }, this.getAllDvir);
  // };

  setDateinQuery = (selectedDate) => {
    const date = this.props.formatRawDate(selectedDate);
    const currenUrl = _get(this, "props.history.location.pathname");
    // const searchParams = _get(this, "props.history.location.search");

    this.props.history.push({
      pathname: currenUrl,
      search: `?selectedDate=${date}`,
    });
  };

  handleAddStatusOpen = () => {
    this.props.setSelectedLog(null);
    this.setState({ openAdd: true, modalName: "" });
  };

  handleAddStatusClose = () => {
    this.setState({ openAdd: false, selLog: null });
  };

  handleRecap = () => {
    this.setState({ openRecap: true });
  };

  handleRecapClose = () => {
    this.setState({ openRecap: false });
  };

  manageDrivers = () => {
    this.setState({ isManageDrivers: true });
  };

  manageDriversClose = () => {
    this.setState({ isManageDrivers: false });
  };

  handleClose = () => {
    this.setState({ isNotificationDialog: false, isAddShippingDialog: false });
  };

  handleLogEdit = async (
    selected,
    name,
    editType,
    index,
    prevLog,
    prevLog2
  ) => {
    const statusId = selected.id;
    const driverId = selected.driverId;
    const response = await this.props.getDriverLogById({
      statusId,
      driverId,
    });
    const selLog = (response && response.status) || {};
    this.setState({
      openAdd: true,
      selLog,
      modalName: name,
      editType,
      logIndex: index,
      prevLog: prevLog,
      prevLog2: prevLog2,
    });
  };

  handleShowAll = () => {
    const showAll = !this.state.showAll;
    this.setState({ showAll });
    this.searchLogs(this.state.selectedDate, showAll);
  };

  certifyDiary = async (media) => {
    const dailyDiary = this.state.dailyDiary;

    if (dailyDiary && dailyDiary[0]) {
      let daily = dailyDiary[0];
      // let { certified, certifyCount } = dailyDiary[0];
      const certified = daily.certified || false;
      const certifyCount = daily.certifyCount || 0;

      if (!certified) {
        daily.certified = true;
        daily.certifyCount = (certifyCount + 1).toString();
        daily.certifyTime = new Date();
        await this.props.updateDriverDaily(...dailyDiary);
      } else {
        dailyDiary[0].certified = false;
        await this.props.updateDriverDaily(...dailyDiary);
        this.setState({ openCertify: false });
      }
      await this.getDriverDaily();
    }
  };

  handleClick = (action) => {
    this.setState({ openUpdateStatus: true });
  };

  handleLogsDownload = () => {
    this.setState({ openLogsDownload: true });
  };

  onMenuSelect = (item) => {
    if (item === `${LABELS.ReassignMultipleStatus}`) {
      this.setState({ isReassign: true });
    } else if (item === `${LABELS.recap}`) {
      this.setState({ openRecap: true });
    } else if (item === `${LABELS.AuditEvent}`) {
      this.setState({ isAuditModal: true });
    } else if (item === `${LABELS.manageDrivers}`) {
      this.setState({ isManageDrivers: true });
    } else if (item === `${LABELS.openInNewTab}`) {
      window.open(window.location.href, "_blank");
    } else if (item === `${LABELS.refresh}`) {
      this.reload();
    } else if (item === `${LABELS.download}`) {
      this.handleLogsDownload();
    } else if (item === `${LABELS.AddMultipleStatus}`) {
      this.setState({
        isMultiAdd: true,
      });
    } else if (item === `${LABELS.EditMultipleStatus}`) {
      this.setState({
        isEditMultiStatus: true,
      });
    } else if (item === "Edit Multiple Status(Admin)") {
      this.setState({
        isAdminMultiStatus: true,
      });
    } else if (item === `${LABELS.AddStatus}`) {
      this.handleAddStatusOpen();
    } else if (item === `${LABELS.SendMessage}`) {
      this.setState({
        openSms: true,
      });
    } else if (item === `${LABELS.SendNotification}`) {
      this.setState({
        isNotificationDialog: true,
      });
    } else if (item === `${LABELS.AddShippingOrTrailer}`) {
      this.setState({
        isAddShippingDialog: true,
      });
    } else if (item === `${LABELS.AddStatusAdmin}`) {
      this.props.setSelectedLog(null);
      this.setState({
        editType: "addAdmin",
        openAdd: true,
        modalName: "",
      });
    } else if (item === `${LABELS.ReassignMultipleStatusAdmin}`) {
      this.setState({
        isReassignAdmin: true,
      });
    }
  };

  render() {
    const {
      classes,
      logBusy,
      timezone,
      selectedDriver = {},
      adminKey,
      appConfig,
      isAdminDs,
      isAdminDsMul,
      isAdminDre,
      isViewOnly,
    } = this.props;
    const {
      loading,
      selectedDate,
      editRequests,
      isNotificationDialog,
      openSms,
      // showAll,
      isDvir,
      dvirs,
      isNoDvir,
    } = this.state;
    const {
      openRecap,
      openAdd,
      selLog,
      isManageDrivers,
      modalName,
      driverLogs = [],
      // allEvents
    } = this.state;
    const isUnity = this.props.isUnity;
    const role = _get(this, "props.profile[0]", "");
    const { allLogs = {}, allDayEvents } = this.state;
    const { dailyDiary, driverCalcus, allEvents = [] } = this.state;
    const driverLastLogs = allLogs.driverLastLogs || {};
    const driverNextLogs = allLogs.driverNextLogs || {};

    const cycle = getLatestEvent(driverCalcus, "cycleResetTimestamp");
    const cycleStart = cycle && cycle.cycleResetTimestamp;
    // const isAddDisabled = isPastDate(cycleStart, selectedDate);
    const logDate = _get(this.state, "dailyDiary[0].date", "");
    const coDriver = _get(
      this.state,
      "dailyDiary[0].coDriver[0].firstName",
      ""
    );
    const isCertified = _get(this.state, "dailyDiary[0].certified", "");

    const activeLogs =
      Array.isArray(allEvents) &&
      allEvents.filter((item) => {
        return (
          item.recordStatus === "ACTIVE" &&
          item.eventType === "CHANGE_DUTY_STATUS" &&
          !item.isLast
        );
      });

    let cycleData = null;
    Array.isArray(driverCalcus) &&
      driverCalcus.forEach((item) => {
        const cycleDate = this.props.getEndOfDay(item.cycleStartTimestamp);
        const date = this.props.getEndOfDay(selectedDate);

        if (item.status === "active" && cycleDate <= date) {
          if (cycleData == null) {
            cycleData = item;
          } else if (
            cycleDate >= this.props.getEndOfDay(cycleData.cycleStartTimestamp)
          ) {
            cycleData = item;
          }
        }
      });

    const {
      isEditMultiStatus,
      isReassign,
      isReassignAdmin,
      openUpdateStatus,
      // isAdminMultiStatus,
      isMultiAdd,
      openLogsDownload,
      isAddShippingDialog,
      isAuditModal,
    } = this.state;

    let multiAssign = [];
    if (isReassign) {
      multiAssign = activeLogs.filter(
        (i) => i.eventCode === "DRIVING" || i.eventCode === "ONDUTY_ND"
      );
    }
    let multiAdd = [];
    if (isMultiAdd) {
      multiAdd = activeLogs.filter(
        (i) => i.eventCode !== "DRIVING" && i.eventCode !== "ONDUTY_ND"
      );
    }
    const profile = _get(this.props, "profile[0]", "");
    const diaryToday = _get(this.state, "dailyDiary[0]", {});

    return (
      <div>
        {openRecap && (
          <RecapDialog
            open={openRecap}
            selectedDate={selectedDate}
            getDriverDaily={this.props.getDriverDaily}
            handleRecapClose={this.handleRecapClose}
            formatRawDate={this.props.formatRawDate}
          />
        )}
        {openUpdateStatus && (
          <UpdateStatusModal
            open={openUpdateStatus}
            selectedDate={selectedDate}
            allEvents={allDayEvents}
            getDriverDaily={this.props.getDriverDaily}
            diaryToday={diaryToday}
            handleClose={() => {
              this.setState({ openUpdateStatus: false });
              this.reload();
            }}
          />
        )}
        {openAdd && (
          <AddDailyStatusModal
            open={openAdd}
            prevLog={this.state.prevLog}
            prevLog2={this.state.prevLog2}
            selectedDriver={selectedDriver}
            selectedDate={selectedDate}
            name={modalName}
            selLog={selLog}
            editType={this.state.editType}
            allDayEvents={allDayEvents}
            dailyDiary={dailyDiary}
            logIndex={this.state.logIndex}
            autoDrLimits={this.state.autoDrLimits}
            handleClose={this.handleAddStatusClose}
            onDone={async () => {
              await this.getEditRequest();
              this.searchLogs(this.state.selectedDate);
              this.setState({
                openAdd: false,
                selLog: false,
                editType: null,
              });
            }}
          />
        )}

        {isEditMultiStatus ? (
          <EditMultipleStatusModal
            selectedDriver={selectedDriver}
            open={isEditMultiStatus}
            close={() => {
              this.searchLogs(this.state.selectedDate);
              this.setState({ isEditMultiStatus: false });
            }}
            logBusy={logBusy}
            startOfDay={this.props.getStartOfDay}
            selectedDate={selectedDate}
            allLogs={activeLogs}
            adminAccess={this.props.adminAccess}
            adminKey={this.props.adminKey}
            formatTime={this.props.formatTime}
            formatRawDate={this.props.formatRawDate}
            driverCalcus={this.state.driverCalcus}
            isDebug={this.props.isDebug}
            adminScope={this.props.adminScope}
            formatDateTimeUtc={this.props.formatDateTimeUtc}
            onDone={async () => {
              await this.getEditRequest();
              this.searchLogs(this.state.selectedDate);
              this.setState({
                isEditMultiStatus: false,
              });
            }}
          />
        ) : null}

        {isReassign ? (
          <ReMultipleStatusModal
            selectedDriver={selectedDriver}
            open={isReassign}
            close={() => {
              this.searchLogs(this.state.selectedDate);
              this.setState({ isReassign: false });
            }}
            selectedDate={selectedDate}
            dailyDiary={dailyDiary}
            allLogs={multiAssign}
            adminAccess={this.props.adminAccess}
            adminKey={this.props.adminKey}
            formatTime={this.props.formatTime}
            formatRawDate={this.props.formatRawDate}
            driverCalcus={this.state.driverCalcus}
            isDebug={this.props.isDebug}
            adminScope={this.props.adminScope}
            formatDateTimeUtc={this.props.formatDateTimeUtc}
            onDone={async () => {
              await this.getEditRequest();
              this.searchLogs(this.state.selectedDate);
              this.setState({
                isReassign: false,
              });
            }}
          />
        ) : null}

        {isReassignAdmin ? (
          <ReMultiStatusAdminModal
            selectedDriver={selectedDriver}
            open={isReassignAdmin}
            close={() => {
              this.searchLogs(this.state.selectedDate);
              this.setState({ isReassignAdmin: false });
            }}
            selectedDate={selectedDate}
            dailyDiary={dailyDiary}
            allLogs={allEvents}
            isAllLog={!!this.state.isAllLog}
            adminAccess={this.props.adminAccess}
            adminKey={this.props.adminKey}
            formatTime={this.props.formatTime}
            formatRawDate={this.props.formatRawDate}
            driverCalcus={this.state.driverCalcus}
            isDebug={this.props.isDebug}
            adminScope={this.props.adminScope}
            formatDateTimeUtc={this.props.formatDateTimeUtc}
            onDone={async () => {
              await this.getEditRequest();
              this.searchLogs(this.state.selectedDate);
              this.setState({
                isReassignAdmin: false,
              });
            }}
          />
        ) : null}

        {isMultiAdd ? (
          <AddMultipleStatusModal
            selectedDriver={selectedDriver}
            open={isMultiAdd}
            close={() => {
              this.searchLogs(this.state.selectedDate);
              this.setState({ isMultiAdd: false });
            }}
            selectedDate={selectedDate}
            dailyDiary={dailyDiary}
            allLogs={multiAdd}
            adminAccess={this.props.adminAccess}
            adminKey={this.props.adminKey}
            formatTime={this.props.formatTime}
            formatRawDate={this.props.formatRawDate}
            driverCalcus={this.state.driverCalcus}
            isDebug={this.props.isDebug}
            adminScope={this.props.adminScope}
            formatDateTimeUtc={this.props.formatDateTimeUtc}
            onDone={async () => {
              // await this.getEditRequest();
              this.searchLogs(this.state.selectedDate);
              this.setState({
                isMultiAdd: false,
              });
            }}
          />
        ) : null}

        {isManageDrivers && (
          <ManageDriverModal
            open={isManageDrivers}
            selectedDate={selectedDate}
            dailyDiary={dailyDiary}
            selectedDriver={selectedDriver}
            handleClose={this.manageDriversClose}
            onDone={async () => {
              await this.getDriverDaily();
              this.setState({ isManageDrivers: false });
            }}
          />
        )}
        {isNotificationDialog && (
          <MessageDialog
            open={isNotificationDialog}
            handleClose={this.handleClose}
            driverId={selectedDriver.userId}
          />
        )}
        {isAddShippingDialog && (
          <AddShippingDialog
            open={isAddShippingDialog}
            handleClose={this.handleClose}
            driverId={selectedDriver.userId}
          />
        )}

        {openSms && (
          <SmsForm
            open={openSms}
            selected={selectedDriver}
            handleClose={() => this.setState({ openSms: false })}
          />
        )}

        {openLogsDownload && (
          <LogCertifyDownload
            hideResetLines={true}
            open={openLogsDownload}
            diary={diaryToday}
            isCertify={openLogsDownload}
            handleClose={() => this.setState({ openLogsDownload: false })}
            dailyDiary={dailyDiary}
            accountId={this.props.accountId}
            selectedDriver={this.props.selectedDriver}
            timezone={timezone}
            dataLoading={loading}
            driverLogs={activeLogs}
            formatTime={this.props.formatTime}
            certifyDiary={this.certifyDiary}
            allDayEvents={allDayEvents}
            logDate={logDate}
            dateToday={dateToday}
            selectedDate={selectedDate}
            updateDriverDaily={this.props.updateDriverDaily}
          />
        )}

        {isAuditModal && (
          <StatusAuditModal
            open={isAuditModal}
            handleClose={() => this.setState({ isAuditModal: false })}
            accountId={this.props.accountId}
            selectedDriver={this.props.selectedDriver}
            timezone={timezone}
            formatTime={this.props.formatTime}
            formatRawDate={this.props.formatRawDate}
            formatDateTime={this.props.formatDateTime}
            selectedDate={selectedDate}
          />
        )}

        <Grid container className={classes.AppBar}>
          <Grid item xs={12} md={4}>
            <DateWithButton
              isSmall={true}
              timezone={timezone}
              disabled={this.state.loading}
              onChange={this.searchLogs}
              selectedDate={selectedDate}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <PendingRequest
              formatDateTime={this.props.formatDateTime}
              deleteRequest={this.props.deleteRequest}
              editRequests={editRequests}
              onDone={this.getEditRequest}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            className={`${classes.textRight} ${classes.menuWrapper}`}
          >
            {/*<Tooltip title="Refresh">
              <IconButton
                aria-label="Reload"
                color="primary"
                onClick={this.reload}
              >
                <SyncIcon />
              </IconButton>
            </Tooltip>
            <div className={classes.sepLine} />*/}
            {/*this.props.adminKey && coDriver && (
                <Tooltip title="Reassign Multiple Status=">
                  <IconButton
                    color="primary"
                    aria-label="ADD"
                    disabled={loading}
                    onClick={() => {
                      this.setState({
                        isReassign: true,
                      });
                    }}
                  >
                    <ReplyIcon />
                  </IconButton>
                </Tooltip>
              )*/}
            {/*this.props.adminKey && coDriver && (
                <Tooltip title="Add Multiple Status=">
                  <IconButton
                    color="primary"
                    aria-label="ADD_MULTIPLE"
                    disabled={loading}
                    onClick={() => {
                      this.setState({
                        isMultiAdd: true,
                      });
                    }}
                  >
                    <ListIcon />
                  </IconButton>
                </Tooltip>
              )*/}

            {/*(this.props.adminKey || profile === "MANAGER") && (
                <Tooltip title="Edit Multiple Status=">
                  <IconButton
                    color="primary"
                    aria-label="ADD"
                    disabled={loading}
                    onClick={() => {
                      this.setState({
                        isEditMultiStatus: true,
                      });
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )*/}
            {/*this.props.adminKey && <div className={classes.sepLine} />*/}

            {/*<Tooltip title="Add Status=">
                <IconButton
                  color="primary"
                  aria-label="ADD"
                  disabled={loading}
                  onClick={this.handleAddStatusOpen}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Tooltip>*/}
            {/*<div className={classes.sepLine} />*/}

            {/*!isViewOnly && (
              <>
                {(this.props.adminKey || profile === "MANAGER") && (
                  <Tooltip title="Add Co-driver">
                    <IconButton
                      aria-label="Reload"
                      color="primary"
                      onClick={this.manageDrivers}
                      disabled={this.state.isFmcsaSend}
                    >
                      <PeopleAltIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </>
            )*/}
            {/*this.props.adminKey && <div className={classes.sepLine} />*/}

            {/*<Tooltip title="Recap">
              <IconButton
                color="primary"
                aria-label="RECAP"
                onClick={this.handleRecap}
              >
                <RestoreIcon />
              </IconButton>
            </Tooltip>
            <div className={classes.sepLine} />

            <IconButton
              color="primary"
              aria-label="Download"
              onClick={this.handleLogsDownload}
              disabled={!isCertified}
            >
              <CloudDownloadIcon style={{ color: "inherit" }} />
            </IconButton>

            <div className={classes.sepLine} />

            <Tooltip title="Open in new tab">
              <IconButton
                aria-label="NewTab"
                color="primary"
                onClick={() => window.open(window.location.href, "_blank")}
              >
                <LaunchIcon />
              </IconButton>
            </Tooltip>


            <div className={classes.sepLine} />
            */}
            {/*adminKey && (
              <Tooltip title="Audit logs">
                <IconButton
                  aria-label="Audit logs"
                  color="primary"
                  onClick={() => this.setState({ isAuditModal: true })}
                >
                  <ContactSupportIcon />
                </IconButton>
              </Tooltip>
            )*/}
            {/*adminKey && <div className={classes.sepLine} />*/}

            {/*this.props.adminKey && (
                <Tooltip title="Send Notification">
                  <IconButton
                    aria-label="Reload"
                    color="primary"
                    onClick={() => {
                      this.setState({
                        isNotificationDialog: true,
                      });
                    }}
                  >
                    {" "}
                    <NotificationsIcon />
                  </IconButton>
                </Tooltip>
              )*/}
            {/*this.props.adminKey && (
                <Tooltip title="Send Message">
                  <IconButton
                    aria-label="Reload"
                    color="primary"
                    onClick={() => {
                      this.setState({
                        openSms: true,
                      });
                    }}
                  >
                    {" "}
                    <SmsIcon />
                  </IconButton>
                </Tooltip>
              )*/}
            {/*adminKey && <div className={classes.sepLine} />*/}

            <ButtonGroup
              variant="text"
              size="small"
              aria-label="Basic button group"
            >
              <Button onClick={() => this.setState({ isDvir: !isDvir })}>
                {!isDvir && isNoDvir && (
                  <WarningAmberIcon fontSize="small" color="error" />
                )}
                {isDvir ? `${LABELS.details}` : `${LABELS.dvir}`}
              </Button>
              <Button onClick={this.reload}>
                <SyncIcon  fontSize="small"/>
              </Button>
              <Button onClick={() => window.open(window.location.href, "_blank")}>
                <LaunchIcon  fontSize="small"/>
              </Button>
              {!isViewOnly && (
                <StatusViewMenu
                  profile={profile}
                  coDriver={coDriver}
                  adminKey={this.props.adminKey}
                  onChange={this.adminMenuClick}
                  onMenuSelect={this.onMenuSelect}
                  isAdminDs={isAdminDs}
                  isAdminDre={isAdminDre}
                  isFmcsaSend={this.state.isFmcsaSend}
                  plan={this.props.plan}
                  downloadDisabled={!isCertified}
                />
              )}
            </ButtonGroup>

            {/*!isViewOnly && (
              <StatusViewMenu
                profile={profile}
                coDriver={coDriver}
                adminKey={this.props.adminKey}
                onChange={this.adminMenuClick}
                onMenuSelect={this.onMenuSelect}
                isAdminDs={isAdminDs}
                isAdminDre={isAdminDre}
                isFmcsaSend={this.state.isFmcsaSend}
                plan={this.props.plan}
                downloadDisabled={!isCertified}
              />
            )*/}

            {role === "DRIVER" && (
              <LogCertifyButton
                dailyDiary={dailyDiary}
                accountId={this.props.accountId}
                selectedDriver={this.props.selectedDriver}
                timezone={timezone}
                dataLoading={loading}
                driverLogs={activeLogs}
                formatTime={this.props.formatTime}
                certifyDiary={this.certifyDiary}
                allDayEvents={allDayEvents}
                logDate={logDate}
                dateToday={dateToday}
                selectedDate={selectedDate}
                updateDriverDaily={this.props.updateDriverDaily}
              />
            )}
          </Grid>
        </Grid>

        {this.state.isFmcsaSend && (
          <Alert severity="warning">{`Logs for selected day are already tranfered to FMCSA.`}</Alert>
        )}

        <div className={classes.root}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12} lg={12}>
              {this.props.adminKey &&
                (driverLogs && driverLogs.length > 700 ? (
                  <Alert severity="error">
                    {`${driverLogs.length} logs were recorded. Driver may have any issue. Check driver's all logs.`}
                  </Alert>
                ) : driverLogs && driverLogs.length > 200 ? (
                  <Alert severity="warning">
                    {`${driverLogs.length} logs were recorded. Driver have an issue. Check driver's all logs.`}{" "}
                  </Alert>
                ) : null)}

              {isDvir ? (
                <DvirTable
                  items={dvirs}
                  count={this.props.count}
                  getAllDvir={this.getAllDvir}
                  formatDateTime={this.props.formatDateTime}
                  limit={this.state.limit}
                  page={this.state.page}
                  handleChange={this.handleChange}
                />
              ) : (
                <DailyStatusInfoTable
                  dailyDiary={dailyDiary}
                  driverLogs={allDayEvents}
                  selectedDriver={selectedDriver}
                  cycleData={cycleData}
                  selectedDate={selectedDate}
                  driverLastLogs={driverLastLogs}
                  driverNextLogs={driverNextLogs}
                  formatRawDate={this.props.formatRawDate}
                  accountBaseUrl={this.props.accountBaseUrl}
                  role={role}
                  handleClick={this.handleClick}
                  adminKey={adminKey}
                  appConfig={appConfig}
                />
              )}
            </Grid>
            {loading ? (
              <Grid item xs={12} md={12} lg={12}>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  <CircularProgress />
                </div>
              </Grid>
            ) : (
              <>
                <Grid item xs={12} md={12} lg={12}>
                  {loading ? (
                    <EmptyLogGrid containerId="dutyStatusLogChart" />
                  ) : (
                    <LogGrid
                      isAllLog={this.state.isAllLog}
                      timezone={timezone}
                      dailyDiary={dailyDiary}
                      driverLogs={allDayEvents}
                      containerId="dutyStatusLogChartLog"
                      formatTime={this.props.formatTime}
                    />
                  )}
                  {this.props.adminKey &&
                    this.props.formatRawDate(selectedDate) === dateToday && (
                      <DriverErrors
                        selectedDriver={selectedDriver}
                        getFireBaseErrors={this.props.getFireBaseErrors}
                      />
                    )}

                  <Violations
                    driverCalcus={driverCalcus}
                    dailyDiary={dailyDiary}
                    selectedDate={selectedDate}
                    formatTime={this.props.formatTime}
                  />
                  <Exceptions
                    dailyDiary={dailyDiary}
                    selectedDate={selectedDate}
                    formatTime={this.props.formatTime}
                  />
                  {!isUnity && this.props.showAllLogs && (
                    <Grid item className={classes.statusCheckBoxWrapper}>
                      <StatusButtons
                        isAllLog={this.state.isAllLog}
                        onChange={(isAllLog) => this.setState({ isAllLog })}
                      />
                      {this.props.adminKey && <DetailButtons
                        isDetailLog={this.state.isDetailLog}
                        onChange={(_isDetailLog) => this.setState({ isDetailLog: _isDetailLog })}
                      />}
                    </Grid>
                  )}
                  <CurrentStatusDetailTableNew
                    logBusy={logBusy}
                    isAdminDs={isAdminDs}
                    isAdminDsMul={isAdminDsMul}
                    profile={this.props.profile}
                    isFmcsaSend={this.state.isFmcsaSend}
                    selectedDate={selectedDate}
                    searchLogs={this.searchLogs}
                    isAllLog={this.state.isAllLog}
                    isDetailLog={this.state.isDetailLog}
                    driverLogs={allDayEvents}
                    handleLogEdit={this.handleLogEdit}
                    dailyDiary={dailyDiary}
                    searchLogStatus={() =>
                      this.searchLogs(this.state.selectedDate)
                    }
                    adminAccess={this.props.adminAccess}
                    adminKey={this.props.adminKey}
                    selectedLog={this.props.selectedLog}
                    formatTime={this.props.formatTime}
                    setSelectedLog={this.props.setSelectedLog}
                    formatRawDate={this.props.formatRawDate}
                    deleteAdminLogs={this.props.deleteAdminLogs}
                    updateAdminLogs={this.props.updateAdminLogs}
                    deleteAdminCalcus={this.props.deleteAdminCalcus}
                    driverCalcus={this.state.driverCalcus}
                    cycleStart={cycleStart}
                    isDebug={this.props.isDebug}
                    adminScope={this.props.adminScope}
                    isQa={this.props.isQa}
                    startOfDay={this.props.getStartOfDay}
                    updateSelectedLogs={(_selectedLogs) =>
                      this.setState({ selectedLogs: _selectedLogs })
                    }
                    showAll={this.state.showAll}
                    formatTimeSec={this.props.formatTimeSec}
                    isUnity={this.props.isUnity}
                    isSmartelds={this.props.isSmartelds}
                    isViewOnly={isViewOnly}
                  />
                </Grid>
              </>
            )}
            {isAdminDsMul && (
              <Grid item xs={12} md={12} lg={12}>
                <AllLogEdit
                  selectedLogs={this.state.selectedLogs}
                  reloadAllLogs={() => this.reload()}
                  formatDateTimeToUtc={this.props.formatDateTimeToUtc}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const adminKey = _get(state, "authData.profile.adminId");
  let showAllLogs = _get(
    state,
    "authData.profile.accountProperties.settings.showAllLogs",
    false
  );
  let isDebug = _get(
    state,
    "authData.profile.accountProperties.settings.debug",
    true
  );
  if (adminKey && isDebug) {
    showAllLogs = true;
  }
  return {
    logBusy: _get(state, "logData.logBusy", false),
    adminAccess: _get(state, "authData.adminAccess", false),
    adminScope: _get(state, "authData.profile.scopes"),
    driverCalcus: _get(state, "logData.driverCalcus", []),
    selectedLog: _get(state, "logData.selectedLog", {}),
    driverBusy: _get(state, "driverData.driverBusy", false),
    selectedDriver: _get(state, "driverData.selectedDriver", {}),
    defaultTimezone: _get(state, "appData.defaultTimezone"),
    timezone: _get(state, "appData.timezone"),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
    accountId: _get(state, "authData.accountId"),
    adminKey: adminKey,
    profile: _get(state, "authData.profile.roles"),
    showAllLogs,
    isDebug: !!adminKey,
    isQa: _get(state, "appData.appConfig"),
    appConfig: _get(state, "appData.appConfig"),
    isUnity: _get(state, "appData.isUnity", false),
    isSmartelds: _get(state, "appData.isSmartelds", false),
    isViewOnly: _get(state, "authData.isViewOnly", false),
    isAdminDs: _get(state, "authData.isAdminDs", false),
    isAdminDre: _get(state, "authData.isAdminDre", false),
    isAdminDsMul: _get(state, "authData.isAdminDsMul", false),
    plan: _get(state, "authData.profile.plan.plan", {}),
    count: state.dvirData.count,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setTimeZone,
      getDriverById,
      getDriverDaily,
      getDriverLogs,
      formatTime,
      formatRawDate,
      formatDateTime,
      getEndOfDay,
      getStartOfDay,
      getDailyLogsDays,
      setSelectedLog,
      deleteDriverLog,
      getEditRequest,
      getDriverLogById,
      deleteRequest,
      getAllCalcus,
      updateDriverDaily,
      getLogPostUrl,
      getLogGetUrl,
      getFireBaseErrors,
      deleteAdminLogs,
      deleteAdminCalcus,
      updateAdminLogs,
      formatDateTimeUtc,
      formatTimeSec,
      getFmcsaHistory,
      formatDateTimeToUtc,
      getAllDvir,
      getAllDvirCount,
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DailyStatusDetail)
);
